import { default as about_45usfgoOlkORBHMeta } from "/tmp/build/pages/about-us.vue?macro=true";
import { default as a_45wide_45range_45of_45equipmentvwe4y4nz5xMeta } from "/tmp/build/pages/advantages/a-wide-range-of-equipment.vue?macro=true";
import { default as high_45qualitywt85L0nbN7Meta } from "/tmp/build/pages/advantages/high-quality.vue?macro=true";
import { default as professional_45approachOq0jQuT08oMeta } from "/tmp/build/pages/advantages/professional-approach.vue?macro=true";
import { default as _91slug_937KqAUatVNvMeta } from "/tmp/build/pages/equipments/[slug].vue?macro=true";
import { default as indexKDjUiNtftQMeta } from "/tmp/build/pages/index.vue?macro=true";
import { default as our_45worksTqhhsC0XmcMeta } from "/tmp/build/pages/our-works.vue?macro=true";
import { default as privacywQGBwHPzCVMeta } from "/tmp/build/pages/privacy.vue?macro=true";
import { default as _91id_9382IdpwjXNHMeta } from "/tmp/build/pages/products/[id].vue?macro=true";
import { default as led_45testcDUX720r7YMeta } from "/tmp/build/pages/promo/led-test.vue?macro=true";
import { default as ledbqmqeNgdFRMeta } from "/tmp/build/pages/promo/led.vue?macro=true";
export default [
  {
    name: about_45usfgoOlkORBHMeta?.name ?? "about-us",
    path: about_45usfgoOlkORBHMeta?.path ?? "/about-us",
    meta: about_45usfgoOlkORBHMeta || {},
    alias: about_45usfgoOlkORBHMeta?.alias || [],
    redirect: about_45usfgoOlkORBHMeta?.redirect || undefined,
    component: () => import("/tmp/build/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: a_45wide_45range_45of_45equipmentvwe4y4nz5xMeta?.name ?? "advantages-a-wide-range-of-equipment",
    path: a_45wide_45range_45of_45equipmentvwe4y4nz5xMeta?.path ?? "/advantages/a-wide-range-of-equipment",
    meta: a_45wide_45range_45of_45equipmentvwe4y4nz5xMeta || {},
    alias: a_45wide_45range_45of_45equipmentvwe4y4nz5xMeta?.alias || [],
    redirect: a_45wide_45range_45of_45equipmentvwe4y4nz5xMeta?.redirect || undefined,
    component: () => import("/tmp/build/pages/advantages/a-wide-range-of-equipment.vue").then(m => m.default || m)
  },
  {
    name: high_45qualitywt85L0nbN7Meta?.name ?? "advantages-high-quality",
    path: high_45qualitywt85L0nbN7Meta?.path ?? "/advantages/high-quality",
    meta: high_45qualitywt85L0nbN7Meta || {},
    alias: high_45qualitywt85L0nbN7Meta?.alias || [],
    redirect: high_45qualitywt85L0nbN7Meta?.redirect || undefined,
    component: () => import("/tmp/build/pages/advantages/high-quality.vue").then(m => m.default || m)
  },
  {
    name: professional_45approachOq0jQuT08oMeta?.name ?? "advantages-professional-approach",
    path: professional_45approachOq0jQuT08oMeta?.path ?? "/advantages/professional-approach",
    meta: professional_45approachOq0jQuT08oMeta || {},
    alias: professional_45approachOq0jQuT08oMeta?.alias || [],
    redirect: professional_45approachOq0jQuT08oMeta?.redirect || undefined,
    component: () => import("/tmp/build/pages/advantages/professional-approach.vue").then(m => m.default || m)
  },
  {
    name: _91slug_937KqAUatVNvMeta?.name ?? "equipments-slug",
    path: _91slug_937KqAUatVNvMeta?.path ?? "/equipments/:slug()",
    meta: _91slug_937KqAUatVNvMeta || {},
    alias: _91slug_937KqAUatVNvMeta?.alias || [],
    redirect: _91slug_937KqAUatVNvMeta?.redirect || undefined,
    component: () => import("/tmp/build/pages/equipments/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexKDjUiNtftQMeta?.name ?? "index",
    path: indexKDjUiNtftQMeta?.path ?? "/",
    meta: indexKDjUiNtftQMeta || {},
    alias: indexKDjUiNtftQMeta?.alias || [],
    redirect: indexKDjUiNtftQMeta?.redirect || undefined,
    component: () => import("/tmp/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: our_45worksTqhhsC0XmcMeta?.name ?? "our-works",
    path: our_45worksTqhhsC0XmcMeta?.path ?? "/our-works",
    meta: our_45worksTqhhsC0XmcMeta || {},
    alias: our_45worksTqhhsC0XmcMeta?.alias || [],
    redirect: our_45worksTqhhsC0XmcMeta?.redirect || undefined,
    component: () => import("/tmp/build/pages/our-works.vue").then(m => m.default || m)
  },
  {
    name: privacywQGBwHPzCVMeta?.name ?? "privacy",
    path: privacywQGBwHPzCVMeta?.path ?? "/privacy",
    meta: privacywQGBwHPzCVMeta || {},
    alias: privacywQGBwHPzCVMeta?.alias || [],
    redirect: privacywQGBwHPzCVMeta?.redirect || undefined,
    component: () => import("/tmp/build/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: _91id_9382IdpwjXNHMeta?.name ?? "products-id",
    path: _91id_9382IdpwjXNHMeta?.path ?? "/products/:id()",
    meta: _91id_9382IdpwjXNHMeta || {},
    alias: _91id_9382IdpwjXNHMeta?.alias || [],
    redirect: _91id_9382IdpwjXNHMeta?.redirect || undefined,
    component: () => import("/tmp/build/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: led_45testcDUX720r7YMeta?.name ?? "promo-led-test",
    path: led_45testcDUX720r7YMeta?.path ?? "/promo/led-test",
    meta: led_45testcDUX720r7YMeta || {},
    alias: led_45testcDUX720r7YMeta?.alias || [],
    redirect: led_45testcDUX720r7YMeta?.redirect || undefined,
    component: () => import("/tmp/build/pages/promo/led-test.vue").then(m => m.default || m)
  },
  {
    name: ledbqmqeNgdFRMeta?.name ?? "promo-led",
    path: ledbqmqeNgdFRMeta?.path ?? "/promo/led",
    meta: ledbqmqeNgdFRMeta || {},
    alias: ledbqmqeNgdFRMeta?.alias || [],
    redirect: ledbqmqeNgdFRMeta?.redirect || undefined,
    component: () => import("/tmp/build/pages/promo/led.vue").then(m => m.default || m)
  }
]