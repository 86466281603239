const phoneByUtmSource = (utmSource) => {
  // phone_direct:  'NUXT_PUBLIC_PHONE_DIRECT',
  // phone_target:  'NUXT_PUBLIC_PHONE_TARGET',
  // phoneDefault: 'NUXT_PUBLIC_PHONE_DEFAULT',
  // https://darkevent.ru/equipments/interactive-tables
  // ?utm_source = yandex.{ source_type }
  // & utm_medium=cpc & utm_term={ keyword }& utm_campaign={ campaign_id }& utm_content={ ad_id }

  let phone

  const config = useRuntimeConfig()

  switch (utmSource) {
    case 'yandex.search':
    case 'yandex.context':
      phone = config.public.phone_direct
      break

    // case 'target':
    //   phone = config.public.phone_target
    //   break

    default:
      phone = config.public.phoneDefault
      break
  }

  return phone
}

export default defineNuxtPlugin(async (NuxtApp) => {
  const utmSourceCookie = await useCookie('utm_source')
  const phoneCookie = await useCookie('current_phone')

  const route = useRoute()

  const utmSource = route.query.utm_source

  if (utmSource) {
    utmSourceCookie.value = utmSource
    phoneCookie.value = phoneByUtmSource(utmSource)
  } else {
    if (!utmSourceCookie.value) {
      utmSourceCookie.value = 'default'
      phoneCookie.value = phoneByUtmSource('default')
    }
  }

  return {
    provide: {
      phone: phoneCookie.value,
    },
  }
})
