import MarkDownIt from 'markdown-it'
const md = new MarkDownIt()

export default defineNuxtPlugin(() => {
  return {
    provide: {
      strapiMedia: (file: string) => useStrapiMedia(file),
      markdown: (string: string) => md.render(string),
    },
  }
})
